<template>
  <div class="add-form">
    <el-form :model="ingressForm" size="small" ref="ingressForm" label-position="top">
      <el-form-item :label="$t('location')" required>
        <checkout-list :data.sync="locationList" />
      </el-form-item>

      <el-form-item
        :label="$t('Namespace')"
        prop="metadata.namespace"
        :rules="[{ required: true, message: $t('rules.require'), trigger: 'change' }]"
      >
        <type-list type="namespace" :data.sync="ingressForm.metadata.namespace" :locationList="locationList" />
      </el-form-item>

      <el-form-item
        :label="$t('ingressName')"
        prop="metadata.name"
        :rules="[
          { required: true, message: $t('rules.require'), trigger: 'blur' },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t('rules.pattern'),
            trigger: 'blur'
          }
        ]"
      >
        <el-input v-model.trim="ingressForm.metadata.name"></el-input>
        <span class="help">{{ $t("tips.nameCommonPattern") }}</span>
      </el-form-item>

      <el-form-item :label="$t('annotations')">
        <edit-labels ref="editLabelForm" />
      </el-form-item>

      <el-form-item :label="$t('tag')">
        <add-labels :labels.sync="ingressForm.metadata.labels" />
        <span class="help">{{ $t("tips.addlabels") }}</span>
      </el-form-item>

      <item
        :data="ingressForm"
        ref="ingressItem"
        :vendor="locationList[0]"
        :region="locationList[1]"
        :cluster="locationList[2]"
        :namespace="ingressForm.metadata.namespace"
      />

      <el-form-item>
        <el-button type="primary" @click="submit" size="small" :loading="loading" plain>
          {{ $t("handle.submit") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ingressSubmit } from "api/ingress";
import { transform } from "lodash";

import CheckoutList from "@/components/CheckoutList";
import Item from "./Item";

export default {
  components: {
    CheckoutList,
    Item
  },

  data() {
    return {
      locationList: [],
      ingressForm: {
        metadata: {
          name: "",
          namespace: this.namespace,
          labels: {},
          annotations: {}
        },
        spec: {
          tls: [],
          rules: []
        }
      },

      loading: false
    };
  },

  computed: {
    vendor() {
      return this.locationList[0];
    },

    region() {
      return this.locationList[1];
    },

    cluster() {
      return this.locationList[2];
    }
  },

  methods: {
    submit() {
      this.$refs["ingressForm"].validate(valid => {
        if (valid) {
          this.$refs["editLabelForm"].$refs["form"].validate(valid => {
            if (valid) {
              this.loading = true;

              this.ingressForm.metadata.annotations = transform(
                this.$refs["editLabelForm"].form.labels,
                function(result, item) {
                  result[item.key] = item.value;
                },
                {}
              );

              this.ingressForm.spec.rules = this.$refs["ingressItem"].rules;

              this.$refs["ingressItem"].rules.forEach(item => {
                if (item.host && item.tls) {
                  this.ingressForm.spec.tls.push({
                    hosts: [item.host],
                    secretName: item.secretName
                  });
                }
              });

              ingressSubmit(this.ingressForm, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
                response => {
                  this.loading = false;
                  if (response.code === 0) {
                    this.$router.push({ path: `/list/Ingress` });
                  }
                }
              );
            }
          });
        }
      });
    }
  }
};
</script>
