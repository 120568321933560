var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-form"},[_c('el-form',{ref:"ingressForm",attrs:{"model":_vm.ingressForm,"size":"small","label-position":"top"}},[_c('el-form-item',{attrs:{"label":_vm.$t('location'),"required":""}},[_c('checkout-list',{attrs:{"data":_vm.locationList},on:{"update:data":function($event){_vm.locationList=$event}}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('Namespace'),"prop":"metadata.namespace","rules":[{ required: true, message: _vm.$t('rules.require'), trigger: 'change' }]}},[_c('type-list',{attrs:{"type":"namespace","data":_vm.ingressForm.metadata.namespace,"locationList":_vm.locationList},on:{"update:data":function($event){return _vm.$set(_vm.ingressForm.metadata, "namespace", $event)}}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('ingressName'),"prop":"metadata.name","rules":[
        { required: true, message: _vm.$t('rules.require'), trigger: 'blur' },
        {
          pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
          message: this.$t('rules.pattern'),
          trigger: 'blur'
        }
      ]}},[_c('el-input',{model:{value:(_vm.ingressForm.metadata.name),callback:function ($$v) {_vm.$set(_vm.ingressForm.metadata, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ingressForm.metadata.name"}}),_c('span',{staticClass:"help"},[_vm._v(_vm._s(_vm.$t("tips.nameCommonPattern")))])],1),_c('el-form-item',{attrs:{"label":_vm.$t('annotations')}},[_c('edit-labels',{ref:"editLabelForm"})],1),_c('el-form-item',{attrs:{"label":_vm.$t('tag')}},[_c('add-labels',{attrs:{"labels":_vm.ingressForm.metadata.labels},on:{"update:labels":function($event){return _vm.$set(_vm.ingressForm.metadata, "labels", $event)}}}),_c('span',{staticClass:"help"},[_vm._v(_vm._s(_vm.$t("tips.addlabels")))])],1),_c('item',{ref:"ingressItem",attrs:{"data":_vm.ingressForm,"vendor":_vm.locationList[0],"region":_vm.locationList[1],"cluster":_vm.locationList[2],"namespace":_vm.ingressForm.metadata.namespace}}),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","size":"small","loading":_vm.loading,"plain":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("handle.submit"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }