<template>
  <div>
    <div class="list-item" v-for="(item, index) in rules" :key="item.host">
      <svg-icon icon-class="Ingress"></svg-icon>
      <div class="content">
        <div class="title">{{ item.host || "-" }}</div>
        <div class="desc">{{ $t("host") }}</div>
      </div>

      <div>
        <el-tooltip effect="dark" :content="$t('handle.edit')" placement="bottom">
          <svg-icon icon-class="edit" class-name="handle-icon" @click.native="editHost(item)"></svg-icon>
        </el-tooltip>

        <el-tooltip effect="dark" :content="$t('handle.delete')" placement="bottom">
          <svg-icon icon-class="delete" class-name="handle-icon" @click.native="rules.splice(index, 1)"></svg-icon>
        </el-tooltip>
      </div>
    </div>

    <div class="list-item" @click="addHost">
      <svg-icon icon-class="add"></svg-icon>
      <div class="content">
        <div class="title">{{ $t("handle.add") }}</div>
        <div class="desc">{{ $t("ingressTips.ingressItemAdd") }}</div>
      </div>
    </div>

    <el-dialog
      title="Host"
      :visible.sync="subDialogVisible"
      top="30px"
      append-to-body
      :close-on-click-modal="false"
      @close="$refs['hostForm'].resetFields()"
    >
      <el-form :model="hostForm" ref="hostForm" size="small" label-position="top">
        <el-form-item prop="host" :label="$t('host')">
          <el-input v-model="hostForm.host"></el-input>
        </el-form-item>

        <el-form-item :label="$t('useHTTPS')">
          <el-switch v-model="hostForm.tls" @change="tlsSwitchChange"> </el-switch>
        </el-form-item>

        <el-form-item :label="$t('Secret')" v-if="hostForm.tls">
          <el-select
            v-model="hostForm.secretName"
            filterable
            @focus="getSecretList"
            :loading="secretListLoading"
            style="width: 100%;"
          >
            <el-option
              v-for="item in secretList"
              :key="item.metadata.name"
              :value="item.metadata.name"
              :label="item.metadata.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-card v-for="(item, index) in hostForm.http.paths" :key="index" shadow="never" style="margin-bottom: 10px;">
          <div slot="header" class="clearfix">
            <span>Path{{ index + 1 }}</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              size="small"
              @click="hostForm.http.paths.splice(index, 1)"
            >
              {{ $t("handle.delete") }}
            </el-button>
          </div>

          <el-form-item
            :label="$t('ingressSubHost')"
            :prop="'http.paths.' + index + '.path'"
            :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
          >
            <el-input v-model.trim="item.path"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('serviceName')"
            :prop="'http.paths.' + index + '.backend.serviceName'"
            :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
          >
            <el-input v-model.trim="item.backend.serviceName"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('servicePort')"
            :prop="'http.paths.' + index + '.backend.servicePort'"
            :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
          >
            <el-input v-model.trim="item.backend.servicePort"></el-input>
          </el-form-item>
        </el-card>

        <div
          class="list-item"
          @click="hostForm.http.paths.push({ path: '', backend: { serviceName: '', servicePort: '' } })"
        >
          <svg-icon icon-class="add"></svg-icon>
          <div class="content">
            <div class="title">{{ $t("handle.add") }}</div>
            <div class="desc">{{ $t("ingressTips.ingressItemAdd") }}</div>
          </div>
        </div>
      </el-form>

      <span slot="footer">
        <el-button type="primary" size="small" @click="hostFormSubmit">
          {{ hostFormType == "add" ? $t("handle.submit") : $t("handle.save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const uuidv4 = require("uuid/v4");
import { cloneDeep, transform } from "lodash";
import { secretListByLocation } from "api/secret";

export default {
  props: {
    data: {
      type: Object
    },

    vendor: {
      type: String
    },

    region: {
      type: String
    },

    cluster: {
      type: String
    },

    namespace: {
      type: String
    }
  },

  data() {
    return {
      hostForm: {
        host: "",
        tls: false,
        secretName: "",
        http: {
          paths: []
        }
      },
      hostFormType: "add",
      subDialogVisible: false,
      rules: [],
      loading: false,
      secretList: [],
      secretListLoading: false
    };
  },

  mounted() {
    let tlsData = [];

    if (!this.data.spec.tls) this.$set(this.data.spec, "tls", []);
    this.data.spec.tls.forEach(tls => {
      let secretName = tls.secretName || "";
      tls.hosts.forEach(host => {
        tlsData.push({
          hosts: [host],
          secretName
        });
      });
    });

    this.rules = transform(
      this.data.spec.rules,
      (result, item) => {
        tlsData.forEach(tls => {
          if ("host" in item && item.host === tls.hosts[0]) {
            this.$set(item, "tls", true);
            this.$set(item, "secretName", tls.secretName);
          }
        });

        result.push({ ...item, id: uuidv4() });
      },
      []
    );
  },

  methods: {
    addHost() {
      this.subDialogVisible = true;
      this.hostFormType = "add";

      this.hostForm = {
        id: uuidv4(),
        host: "",
        tls: false,
        http: {
          paths: []
        }
      };
    },

    editHost(row) {
      this.subDialogVisible = true;
      this.hostFormType = "edit";

      if (!row.http) row.http = { paths: [] };
      this.hostForm = cloneDeep(row);
    },

    getSecretList() {
      if (this.namespace) {
        this.secretListLoading = true;

        secretListByLocation({
          vendor: this.vendor,
          region: this.region,
          cluster: this.cluster,
          namespace: this.namespace
        }).then(response => {
          this.secretListLoading = false;
          if (response.code === 0) {
            this.secretList = response.data.items;
          }
        });
      }
    },

    hostFormSubmit() {
      this.$refs["hostForm"].validate(valid => {
        if (valid) {
          if (this.hostFormType == "edit") {
            this.rules.forEach((item, index, arr) => {
              if (item.id == this.hostForm.id) arr.splice(index, 1, cloneDeep(this.hostForm));
            });
          }

          if (this.hostFormType == "add") {
            this.rules.push(cloneDeep(this.hostForm));
          }

          this.subDialogVisible = false;
        }
      });
    },

    tlsSwitchChange(val) {
      if (!val) {
        this.$set(this.hostForm, "secretName", "");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
</style>
